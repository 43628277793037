<template>
  <div class="dash-home">
    <el-card v-loading="loading">
      <div class="wrap">
        <div class="left">
          <p class="top">店铺: {{info.shopName}} <span class="grap">|</span> ID: {{info.shopId}}</p>
          <p class="bottom">店铺状态：
            <el-tag type="success" size="small">{{info.shopState}}</el-tag>
          </p>
        </div>
        <div class="right">
          <div class="item">
            <p class="title">可付金额</p>
            <p class="content">{{info.payableBalance}}€</p>
          </div>
          <div class="item">
            <p class="title">未结金额</p>
            <p class="content">{{info.pendingBalance}}€</p>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      info: {},
      loading: true
    }
  },
  async created() {
    this.info = await this.getUserInfo()
    this.loading = false
  },
  methods: {
    ...mapActions(['getUserInfo']),
  }
}
</script>
<style lang="less">
.dash-home {
  .wrap {
    display: flex;
    justify-content: space-between;
  }
  .left {
    .top {
      margin-bottom: 12px;
      color: rgba(0,0,0,.85);
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      .grap {
        display: inline-block;
        margin: 0 10px;
        color: rgba(0,0,0,.25);
      }
    }
    .bottom {
      color: rgba(0,0,0,.25);
    }
  }
  .right {
    display: flex;
    .item {
      margin-left: 20px;
    }
    .title {
      text-align: right;
      color: rgba(0,0,0,.45);
      font-size: 14px;
    }
    .content {
      color: rgba(0,0,0,.85);
      font-size: 24px;
    }
  }
}
</style>